import {AxiosResponse} from 'axios';
import {OrderListFilter} from 'hooks/useOrderList';
import {Order, PaginatedResponse} from 'types';
import {ApiClient} from './api-client';
import {format} from 'date-fns';

export class OrderService extends ApiClient {
  async getAllPaginated(
    filter: OrderListFilter = {}
  ): Promise<AxiosResponse<PaginatedResponse<Order>>> {
    return this.get('/routes/deliveries/', {
      limit: 40,
      offset: filter.offset,
      user_id: filter.userId,
      search: filter?.search,
      order_by: filter?.sortBy,
      preference_date: filter.preference_date
        ? format(filter.preference_date, 'yyyy-MM-dd')
        : undefined,
      preference_time: filter.preference_time,
      priority: filter.priority,
      processed:
        typeof filter.unAssignedOnly === 'undefined'
          ? undefined
          : !filter.unAssignedOnly,
      zip_code: filter.filter
        ? filter.filter?.length > 0
          ? filter.filter?.reduce((pr, curr) => [...pr, curr.label], [])
          : undefined
        : undefined,
    });
  }
}
