import Typography from 'components/Typography';
import PageHeader from 'components/PageHeader';
import ListWrapper from 'components/ListWrapper';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import Button from 'components/Button';
import {useForm} from 'react-hook-form';
import {validateEmail} from 'utils/validators';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import api from 'api';
import toast from 'react-hot-toast';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import {addressToString} from 'helpers/address';

type FormValues = {
  name: string;
  email: string;
  address: string;
  phone: string;
  id: string;
  website: string;
};
function CompanyDetails() {
  const {currentUser} = useAuth();
  const {
    register,
    formState: {errors},
    handleSubmit,
    setValue,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      name: get(currentUser, 'company.name', ''),
      email: get(currentUser, 'company.email', ''),
      address: get(currentUser, 'company.address', ''),
      phone:
        '(' +
        get(currentUser, 'company.phone', '').substring(0, 3) +
        ') ' +
        get(currentUser, 'company.phone', '').substring(3, 6) +
        '-' +
        get(currentUser, 'company.phone', '').substring(6, 10),
      id: get(currentUser, 'company.id', ''),
      website: get(currentUser, 'company.website', ''),
    },
  });

  const saveHandler = async (data: FormValues) => {
    try {
      const company_id = get(currentUser, 'company.id', 0);
      await api.put(`/companies/companies/${company_id}/`, {
        ...data,
        phone: data.phone.replace(/\D/g, ''),
      });
      toast.success('Company details updated');
    } catch (error) {
      toast.error(get(error, 'response.data.error_message', '') || 'Error');
    }
  };

  return (
    <div>
      <PageHeader>
        <Typography variant="h2">Company details</Typography>
      </PageHeader>
      <ListWrapper
        style={{
          maxWidth: 700,
          paddingLeft: 20,
          display: 'flex',
          paddingTop: 20,
          paddingRight: 20,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3">Details</Typography>
          </div>
          <FormGroup>
            <TextField
              label="Name"
              placeholder="Name"
              {...register('name', {
                required: 'Required',
              })}
              error={errors.name?.message}
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Email"
              disabled
              placeholder="Email"
              {...register('email', {
                required: 'Required',
                validate: validateEmail,
              })}
              error={errors.email?.message}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <GooglePlaceAutoComplete
              label="Address"
              placeholder="Address"
              defaultValue={getValues().address}
              onSelectPlace={(data) => {
                setValue(
                  'address',
                  addressToString({
                    address_line_1: data.addressLine1,
                    city: data.city,
                    state: data.state,
                    zipcode: data.zipcode,
                    address_line_2: '',
                    is_primary: false,
                    is_valid: true,
                    label: '',
                    latitude: data.lat,
                    longitude: data.lon,
                    unit_address: '',
                    id: 0,
                    type: 'billing',
                  })
                );
              }}
              error={errors?.address?.message}
              options={{
                types: ['address'],
              }}
            />
          </FormGroup>

          <FormGroup columns={1}>
            <TextField
              label="Phone number"
              placeholder="(123) 456-7890"
              {...register('phone', {
                required: 'Required',
                minLength: {
                  value: 14,
                  message: 'Enter a valid phone number',
                },
              })}
              type="tel"
              onChange={(e) => {
                let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                let formatted = '';

                if (input.length > 0) formatted += '(' + input.substring(0, 3);
                if (input.length >= 4)
                  formatted += ') ' + input.substring(3, 6);
                if (input.length >= 7)
                  formatted += '-' + input.substring(6, 10);

                e.target.value = formatted;
                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
              error={errors.phone?.message}
            />
          </FormGroup>
          <FormGroup columns={1}>
            <TextField
              label="Website"
              placeholder="https://"
              {...register('website', {
                required: 'Required',
              })}
              error={errors.website?.message}
            />
          </FormGroup>
        </div>
        <Button
          onClick={handleSubmit(saveHandler)}
          style={{marginLeft: 'auto', marginBottom: 10, marginTop: 30}}
          color="green"
        >
          Save
        </Button>
      </ListWrapper>
    </div>
  );
}

export default CompanyDetails;
