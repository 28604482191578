import {Role} from 'types';

const roles: {value: Role; label: string}[] = [
  {
    value: 'tech',
    label: 'Operations - prepare orders to be "Ready for Dispatch"',
  },
  {value: 'driver', label: 'Driver - execute delivery (driver app)'},
  {
    value: 'dispatcher',
    label: 'Dispatch - assemble field team and organize routes  ',
  },
  {
    value: 'management',
    label: 'Manager - control access, billing plan, and view whole system',
  },
  {
    value: 'customer_service',
    label: 'Customer Service - confirms details and payment',
  },
];

export default roles;
