import Button from 'components/Button';
import DataTable from './DataTable';
import ListActionsWrapper from 'components/ListActionsWrapper';
import ListWrapper from 'components/ListWrapper';
import SearchInput from 'components/SearchInput';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';

import useTeams from './useTeams';

import styles from './Teams.module.scss';
import {DataItem, DataTableSchemaItem, Driver} from 'types';
import {useHistory} from 'react-router-dom';
import {DragDropContext, DropResult} from 'react-beautiful-dnd';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'dropdown',
    header: '',
    sortable: true,
    colWidth: '40px',
  },
  {
    dataKey: 'name',
    header: 'Team Name',
    sortable: true,
  },
  // {
  //   dataKey: 'members',
  //   header: 'Team Members',
  // },
  // {
  //   dataKey: 'model',
  //   header: 'Vehicle Name',
  //   sortable: true,
  // },
  // {
  //   dataKey: 'travel_method',
  //   header: 'Travel method',
  //   align: 'center',
  //   sortable: false,
  // },
  {
    dataKey: 'view',
    header: '',
    align: 'right',
  },
];

const SUB_SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'first_name',
    header: 'Name',
  },
  {
    dataKey: 'last_name',
    header: 'Last name',
  },
  {
    dataKey: 'phone',
    header: 'Phone number',
  },
  {
    dataKey: 'active',
    header: 'Active',
  },
  {dataKey: 'view', header: '', colWidth: '150px'},
];

function Vehicles() {
  const {
    data,
    isLoading,
    sortBy,
    sortOrder,
    onSort,
    keyWord,
    setKeyWord,
    setData,
    showSubMembers,
    updateTeams,
    setSelectedRows,
    selectedRows,
  } = useTeams();
  const {push} = useHistory();

  const onAddMemberClick = (item: DataItem) => {
    push(`/people/employees/new`, {
      state: {
        team_id: item.id === 'default' ? null : item.id,
      },
    });
  };

  const reorder = (list: Driver[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result: DropResult) => {
    const {source, destination, draggableId} = result;

    if (
      !destination ||
      !source ||
      source.droppableId === destination.droppableId
    ) {
      if (destination && source.droppableId === destination.droppableId) {
        const clonedData = [...data];
        const teamId = source.droppableId.split('-')[1];

        const index = clonedData.findIndex(
          (d) => d.id === (teamId === 'default' ? 'default' : Number(teamId))
        );

        clonedData[index].drivers = reorder(
          clonedData[index].drivers || [],
          source.index,
          destination.index
        );

        setData(clonedData);
        return;
      }
      return;
    }

    const clonedData = [...data];

    const toId = destination.droppableId.split('-')[1];
    const fromId = source.droppableId.split('-')[1];

    const toIndex = clonedData.findIndex(
      (d) => d.id === (toId === 'default' ? 'default' : Number(toId))
    );

    const fromIndex = clonedData.findIndex(
      (d) => d.id === (fromId === 'default' ? 'default' : Number(fromId))
    );

    const memberId = draggableId.split('-')[1];

    const member = clonedData[fromIndex].drivers?.find(
      (d) => d.id === Number(memberId)
    );

    clonedData[fromIndex].drivers = clonedData[fromIndex].drivers?.filter(
      (d) => d.id !== Number(memberId)
    );

    clonedData[toIndex].drivers = [
      ...(clonedData[toIndex].drivers || []),
      member as any,
    ];

    updateTeams(
      {
        id: fromId,
        drivers: clonedData[fromIndex].drivers?.map((d) => d.id) || [],
        name: clonedData[fromIndex].name,
        model: clonedData[fromIndex].model,
        travel_method: clonedData[fromIndex].travel_method || '',
      },
      {
        id: toId,
        drivers: clonedData[toIndex].drivers?.map((d) => d.id) || [],
        name: clonedData[toIndex].name,
        model: clonedData[toIndex].model,
        travel_method: clonedData[toIndex].travel_method || '',
      }
    );

    setData(clonedData);
    setSelectedRows(toId === 'default' ? 'default' : Number(toId));
  };

  return (
    <div className={styles.main_container}>
      <PageHeader>
        <Typography variant="h2">Teams</Typography>
        <Button to="/people/teams/new" component="a" color="green">
          New Team
        </Button>
      </PageHeader>

      <ListWrapper>
        <ListActionsWrapper className={styles.list_header}>
          <SearchInput
            value={keyWord}
            onChange={(val) => {
              setKeyWord(val.target.value);
              sessionStorage.setItem(`search_vehicle`, val.target.value);
            }}
            size="sm"
          />
        </ListActionsWrapper>
        <DragDropContext onDragEnd={handleDragEnd}>
          <DataTable
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={onSort}
            showSubMembers={showSubMembers}
            selectedRows={selectedRows}
            data={data as any}
            onAddMemberClick={onAddMemberClick}
            schema={SCHEMA}
            subSchema={SUB_SCHEMA}
            loading={isLoading}
            selectable={false}
            onClick={(row) =>
              setSelectedRows((pr) => (pr !== row.id ? row.id : 0))
            }
          />
        </DragDropContext>
      </ListWrapper>
    </div>
  );
}

export default Vehicles;
