import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';

import {Customer} from 'types';
import {getFullName} from 'helpers/user';

import IdentificationCard from './icons/IdentificationCard.svg';

import styles from '../RequestDetails.module.scss';
import {Link} from 'react-router-dom';
import {Address} from './DeliveryAdressType';
import ArrowBottomIcon from './icons/ArrowBottomIcon';
import {get} from 'lodash';

interface Props {
  pharmacy: {
    name: string;
    phone: string;
    address: string;
  };
  customer: Customer;
  deliveryData: Address;
  modalHandler: () => void;
}

function CustomerDetails({
  pharmacy,
  customer,
  deliveryData,
  modalHandler,
}: Props) {
  return (
    <Card>
      <CardContent>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography component="p" variant="label">
              Customer
            </Typography>
            {!!get(customer, 'patient_no', null) && (
              <Typography className={styles.text} component="p" variant="label">
                #{get(customer, 'patient_no', null)}
              </Typography>
            )}
          </div>
          <Typography className={styles.fullname} component="p" variant="h2">
            {getFullName(customer.user)}
          </Typography>
          <Link to={`/people/customers/${customer.id}/edit`}>
            <img
              className={styles.identification_card}
              alt="Identification card"
              src={IdentificationCard}
            />
          </Link>
        </div>

        <div className={styles.info}>
          <Typography component="p" variant="label">
            Phone Number
          </Typography>
          <Typography component="p" variant="h3">
            {customer.user.phone || '-'}
          </Typography>
        </div>
        <div className={styles.info}>
          <Typography component="p" variant="label">
            Address
          </Typography>
          <button onClick={modalHandler} className={styles.address_button}>
            <Typography
              className={styles.delivery_address}
              component="p"
              variant="h3"
            >
              {get(deliveryData, 'address_line_1', '')},{' '}
              {get(deliveryData, 'city', '')}, {get(deliveryData, 'state', '')},{' '}
              {get(deliveryData, 'zipcode', '')}
              {get(deliveryData, 'unit_address', '')
                ? `, ${get(deliveryData, 'unit_address', '')}`
                : ''}
            </Typography>
            {!get(deliveryData, 'is_valid', true) && (
              <span className={styles.wrongAddress}>issue</span>
            )}
            <ArrowBottomIcon />
          </button>
        </div>
      </CardContent>
    </Card>
  );
}

export default CustomerDetails;
