import {useEffect, useRef, useState} from 'react';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import {get, truncate} from 'lodash';

import Avatar from 'components/Avatar';

import MenuIcon from './MenuIcon';
import UserModal from './UserModal';

import useAuth from 'hooks/useAuth';
import {getFullName} from 'helpers/user';
import useUi from 'hooks/useUi';
import {MenuItemName} from 'types';

import logo from '../../assets/icon.png';
// import logo_expanded from './images/logo_magic_expand.png';

import styles from './Sidebar.module.scss';
import classNames from 'classnames';
import Onboarding from 'Onboarding';
import Guide from './Guide';

interface SubmenuItem {
  title: string;
  path: string;
  exact?: boolean;
}

interface MenuItem {
  name: MenuItemName;
  title: string;
  path: string;
  exact?: boolean;
  subitems?: SubmenuItem[];
}

function Sidebar() {
  const {currentUser, setUser} = useAuth();
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const {pathname} = useLocation();
  const {push} = useHistory();

  const MENU_ITEMS: MenuItem[] = [
    {name: 'package', title: 'Offerings', path: '/products', exact: false},
    // {name: 'requests', title: 'Requests', path: '/requests'},
    {name: 'rx', title: 'Orders', path: '/orders'},
    {
      name: 'truck',
      title: 'Dispatch',
      path: '/deliveries/delivery_planner',
      // subitems: [
      //   {title: 'Delivery planner', path: '/deliveries/delivery_planner'},
      //   //{title: 'All deliveries', path: '/deliveries', exact: true},
      //   {title: 'Instore pickups', path: '/deliveries/in-store_pickups'},
      // ],
    },
    {
      name: 'transactions',
      title: 'Invoices',
      path: '/transactions',
      exact: false,
    },
    // {
    //   name: 'customers',
    //   title: 'Customers',
    //   path: '/users',
    // },
    // {
    //   name: 'employees',
    //   title: 'Employees',
    //   path: '/employees',
    // },
    {
      name: 'members',
      title: 'People',
      path: '/people',
      subitems: [
        {
          title: 'Customers',
          path: '/people/customers',
        },
        {
          title: 'Staff',
          path: '/people/employees',
        },
        {
          title: 'Teams',
          path: '/people/teams',
        },
      ],
    },
    {
      name: 'gateway',
      title: 'Settings',
      subitems: [
        {
          title: 'Billing',
          path: get(currentUser, 'company.plan')
            ? '/settings/billing'
            : '/settings/plans',
        },
        {title: 'Integrations', path: '/settings/gateway'},
        {title: 'Company', path: '/settings/company'},
      ],
      path: '/settings',
    },
  ];

  const [showTooltip, setShowTooltip] = useState(false);
  const [guideOpen, setGuideOpen] = useState(!currentUser?.is_onboarded);

  const {sidebarExtended, setSidebarExtended} = useUi();
  const [showGuide, setShowGuide] = useState(false);

  const text = useRef(MENU_ITEMS[0].title);
  const top = useRef(0);

  useEffect(
    function () {
      setIsUserModalOpen(false);
    },
    [pathname]
  );

  return (
    <>
      {showTooltip && sidebarExtended && (
        <span style={{top: 250 + top.current * 45}} className={styles.toolt}>
          {text.current}
        </span>
      )}
      <div
        className={classNames(styles.root, {
          [styles['--narrow']]: sidebarExtended,
        })}
      >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img
            onClick={() => setSidebarExtended(!sidebarExtended)}
            className={classNames(
              styles.logo,
              !sidebarExtended && styles.logoActive
            )}
            alt="magic logo"
            src={logo}
          />
          <p style={{color: '#fff', fontSize: 35}}>Magic</p>
        </div>
        <div className={styles.nav_padding} />
        <nav className={styles.nav}>
          <ul className={styles.menu}>
            {MENU_ITEMS.slice(
              0,
              currentUser?.role === 'management'
                ? MENU_ITEMS.length
                : MENU_ITEMS.length - 1
            ).map((item, index) => (
              <li
                onMouseEnter={() => {
                  text.current = item.title;
                  top.current = index;
                  setShowTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowTooltip(false);
                }}
                className={classNames(
                  styles.menu_item,
                  `sidebar-menu-${index}`
                )}
                key={item.name}
              >
                <NavLink
                  to={item.path}
                  activeClassName={styles.active}
                  exact={item.exact}
                  style={{position: 'relative'}}
                >
                  <MenuIcon
                    className={styles.menu_item__icon}
                    name={item.name}
                  />
                  <span className={styles.text}>{item.title}</span>
                  {/* {sidebarExtended && (
                    <span className={styles.tooltiptext}>{item.title}</span>
                  )} */}
                </NavLink>

                {item.subitems && (
                  <ul
                    className={classNames(styles.submenu, {
                      [styles['--hidden']]: !pathname.includes(item.path),
                    })}
                  >
                    {item.subitems.map((subitem, subIndex) => (
                      <li
                        className={classNames(
                          styles.submenu__item,
                          `sidebar-sub-menu-${subIndex}`
                        )}
                        key={subitem.path}
                      >
                        <NavLink
                          to={subitem.path}
                          activeClassName={styles['--active']}
                          exact={subitem.exact}
                        >
                          {subitem.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          {showGuide && (
            <Onboarding
              toggleSidebar={() => {
                setSidebarExtended(false);
                push('/people/customers');
              }}
              onFinish={() => {
                setShowGuide(false);
                // push('/deliveries/delivery_planner');
              }}
              steps={[
                {
                  disableBeacon: true,
                  target: '.sidebar-menu-0',
                  title: 'Offerings',
                  content:
                    'Add the products and services you provide to your customers.',
                },
                {
                  target: '.sidebar-sub-menu-1',
                  title: 'Staff',
                  disableBeacon: true,

                  content: 'Build your team by adding your list of staff.',
                },
                {
                  disableBeacon: true,
                  target: '.sidebar-sub-menu-0',
                  title: 'Customers',
                  content:
                    'Easily view and manage your customer list in one place.',
                },
                {
                  target: '.sidebar-sub-menu-2',
                  title: 'Teams',
                  disableBeacon: true,
                  content: 'You can create your team from here',
                },
                {
                  disableBeacon: true,
                  target: '.sidebar-menu-1',
                  title: 'Orders',
                  content:
                    'Track all your orders and send payment links to customers seamlessly.',
                },
                {
                  disableBeacon: true,
                  target: '.sidebar-menu-2',
                  title: 'Dispatch',
                  content:
                    'Assign orders and efficiently route them to your team members.',
                },
                {
                  disableBeacon: true,
                  target: '.sidebar-menu-3',
                  title: 'Invoices',
                  content:
                    'Monitor and analyze your earnings with ease using detailed invoice tracking.',
                },
                {
                  target: '.sidebar-menu-5',
                  title: 'Settings',
                  disableBeacon: true,
                  content: 'You can manage your billing account from here',
                },
              ]}
            />
          )}
        </nav>
        <div className={styles.user_wrapper}>
          <div className={styles.user} onClick={() => setIsUserModalOpen(true)}>
            <div className={styles.user__details}>
              <span className={styles.user__name}>
                {getFullName(currentUser)}
              </span>
              <span className={styles.user__email}>
                {truncate(currentUser?.email, {length: 20, omission: '...'})}
              </span>
            </div>
            <Avatar
              className={styles.user__avatar}
              file={currentUser?.avatar}
            />
          </div>
        </div>
        <UserModal
          isOpen={isUserModalOpen}
          onClose={() => setIsUserModalOpen(false)}
        />
        <Guide
          onClose={(isStart) => {
            setShowGuide(isStart);
            setGuideOpen(false);
            if (isStart) {
              localStorage.setItem('show-onboarding', 'true');
            }
          }}
          isOpen={guideOpen}
        />
      </div>
      <div
        onClick={() => {
          if (currentUser) setUser({...currentUser, is_onboarded: false});
          if (pathname !== '/deliveries/delivery_planner') {
            setShowGuide(true);
          }
        }}
        className={styles.sidebar_help}
      >
        ?
      </div>
    </>
  );
}

export default Sidebar;
