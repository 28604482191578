import Joyride from 'react-joyride';

function Onboarding({
  steps,
  onFinish,
  toggleSidebar,
}: {
  steps: any[];
  onFinish?: () => void;
  toggleSidebar?: () => void;
}) {
  return (
    <Joyride
      continuous
      hideBackButton
      showProgress
      callback={(data) => {
        if (
          toggleSidebar &&
          data.action === 'next' &&
          data.step.title === 'Offerings'
        ) {
          toggleSidebar();
        }
        if (onFinish && data.action === 'reset') {
          onFinish();
        }
      }}
      steps={steps}
    />
  );
}

export default Onboarding;
