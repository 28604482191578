import React from 'react';
import classNames from 'classnames';

import {DataItem, DataTableSchemaItem} from 'types';

import styles from '../DataTable.module.scss';
import {get, isArray} from 'lodash';
import Header from './Header';
import {getTemplateColumns} from '../DataTable';
import {Draggable, Droppable} from 'react-beautiful-dnd';

interface Props {
  schema: DataTableSchemaItem[];
  subSchema: DataTableSchemaItem[];
  data: DataItem[];
  selectable: boolean;
  selectedRows: number | string;
  style: any;
  handleSelectSingle?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: (item: DataItem) => any;
  onAddMemberClick?: (item: DataItem) => any;
  showSubMembers: number | string;
}

function Data({
  schema,
  subSchema,
  data,
  selectable,
  selectedRows,
  onClick,
  onAddMemberClick,
  style,
  showSubMembers,
}: Props) {
  return (
    <div>
      {data.map((row) => (
        <div
          style={{
            margin: '10px 0',
          }}
          key={`row-${row.id}`}
        >
          <Droppable droppableId={`row-${row.id}`}>
            {(provided: any) => (
              <div ref={provided.innerRef}>
                <div
                  className={classNames(styles.row, styles.data_row)}
                  style={style}
                  onClick={() => onClick && onClick(row)}
                >
                  {schema.map((s, index) => (
                    <div
                      key={`cell-${row.id}-${index}`}
                      className={classNames(
                        styles.cell,
                        {
                          [styles[`--${s.align}`]]: s.align,
                        },
                        [styles[`${s.dataKey}`]],
                        row.id === selectedRows &&
                          s.dataKey === 'dropdown' &&
                          styles.rotate
                      )}
                    >
                      {row[s.dataKey]}
                    </div>
                  ))}
                </div>
                {showSubMembers === row.id && (
                  <div className={styles.sub_members}>
                    <Header
                      schema={subSchema}
                      sortBy="asc"
                      sortOrder="asc"
                      style={{
                        gridTemplateColumns: getTemplateColumns(
                          subSchema,
                          selectable
                        ),
                      }}
                    />
                    {isArray(get(row, 'drivers', [])) &&
                      ((get(row, 'drivers', []) as any[]) || []).map(
                        (childrow, index) => (
                          <Draggable
                            draggableId={`childrow-${childrow.id}`}
                            index={index}
                            key={`childrow-${childrow.id}`}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className={classNames(
                                    styles.row,
                                    styles.data_row
                                  )}
                                  style={{
                                    gridTemplateColumns: getTemplateColumns(
                                      subSchema,
                                      selectable
                                    ),
                                    backgroundColor: !snapshot.isDragging
                                      ? 'transparent'
                                      : '#e5e5e5',
                                  }}
                                >
                                  {subSchema.map((s, index) => (
                                    <div
                                      key={`cell-${childrow.id}-${index}`}
                                      className={classNames(
                                        styles.cell,
                                        {
                                          [styles[`--${s.align}`]]: s.align,
                                        }[styles[`${s.dataKey}`]]
                                      )}
                                    >
                                      {childrow[s.dataKey]}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                    <div
                      onClick={() => onAddMemberClick && onAddMemberClick(row)}
                      className={styles.add_driver}
                    >
                      + Add team member
                    </div>
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ))}
    </div>
  );
}

export default Data;
