// import {Link} from 'react-router-dom';

import Typography from 'components/Typography';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import {getFullName} from 'helpers/user';
import useAuth from 'hooks/useAuth';
import styles from './UserModal.module.scss';
import FormGroup from 'components/FormGroup';
import TextField from 'components/TextField';
import {GooglePlaceAutoComplete} from 'components/GooglePlaceAutoComplete';
import {addressToString} from 'helpers/address';
import {useForm} from 'react-hook-form';
import {get} from 'lodash';
import api from 'api';
import toast from 'react-hot-toast';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type FormValues = {
  name: string;
  email: string;
  address: string;
  phone: string;
  id: string;
  website: string;
};

function UserModal({isOpen, onClose}: Props) {
  const {currentUser, logout, refetch} = useAuth();
  const {
    register,
    formState: {errors},
    handleSubmit,
    setValue,
    getValues,
  } = useForm<FormValues>({
    defaultValues: {
      name: get(currentUser, 'company.name', ''),
      email: get(currentUser, 'company.email', ''),
      address: get(currentUser, 'company.address', ''),
      phone:
        '(' +
        get(currentUser, 'company.phone', '').substring(0, 3) +
        ') ' +
        get(currentUser, 'company.phone', '').substring(3, 6) +
        '-' +
        get(currentUser, 'company.phone', '').substring(6, 10),
      id: get(currentUser, 'company.id', ''),
      website: get(currentUser, 'company.website', ''),
    },
  });

  const onSubmit = async (data: FormValues) => {
    try {
      const company_id = get(currentUser, 'company.id', 0);
      await api.put(`/companies/companies/${company_id}/`, {
        ...data,
        phone: data.phone.replace(/\D/g, ''),
      });
      refetch(false);
      toast.success('Company details updated');
      onClose();
    } catch (error) {
      toast.error(get(error, 'response.data.error_message', '') || 'Error');
    }
  };

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      <Typography variant="h2">Profile</Typography>

      <div className={styles.root}>
        <Avatar className={styles.avatar} file={currentUser?.avatar} />
        <div className={styles.details}>
          <Typography className={styles.name} component="p" variant="h3">
            {getFullName(currentUser)}
          </Typography>
          <div className={styles.label}>
            <Typography variant="label">Email: {currentUser?.email}</Typography>
            <Typography variant="label">Phone: {currentUser?.phone}</Typography>
          </div>
        </div>
        {/* <Link to={`/settings/company`} className={styles.edit}>
          Edit
        </Link> */}
      </div>
      {currentUser?.role === 'management' && (
        <>
          <Typography style={{marginTop: 50}} variant="h2">
            Company
          </Typography>
          <div>
            <FormGroup>
              <TextField
                label="Company name"
                placeholder="Name"
                {...register('name', {
                  required: 'Required',
                })}
                error={errors.name?.message}
              />
            </FormGroup>
            <FormGroup columns={1}>
              <GooglePlaceAutoComplete
                label="Address"
                placeholder="Address"
                defaultValue={getValues().address}
                onSelectPlace={(data) => {
                  setValue(
                    'address',
                    addressToString({
                      address_line_1: data.addressLine1,
                      city: data.city,
                      state: data.state,
                      zipcode: data.zipcode,
                      address_line_2: '',
                      is_primary: false,
                      is_valid: true,
                      label: '',
                      latitude: data.lat,
                      longitude: data.lon,
                      unit_address: '',
                      id: 0,
                      type: 'billing',
                    })
                  );
                }}
                error={errors?.address?.message}
                options={{
                  types: ['address'],
                }}
              />
            </FormGroup>

            <FormGroup columns={1}>
              <TextField
                label="Phone number"
                placeholder="(123) 456-7890"
                {...register('phone', {
                  required: 'Required',
                  minLength: {
                    value: 14,
                    message: 'Enter a valid phone number',
                  },
                })}
                type="tel"
                onChange={(e) => {
                  let input = e.target.value.replace(/\D/g, ''); // Faqat raqamlarni qoldirish
                  let formatted = '';

                  if (input.length > 0)
                    formatted += '(' + input.substring(0, 3);
                  if (input.length >= 4)
                    formatted += ') ' + input.substring(3, 6);
                  if (input.length >= 7)
                    formatted += '-' + input.substring(6, 10);

                  e.target.value = formatted;
                  // e.target.value = e.target.value.replace(/[^0-9]/g, '');
                }}
                error={errors.phone?.message}
              />
            </FormGroup>
            <FormGroup columns={1}>
              <TextField
                label="Website"
                placeholder="https://"
                {...register('website', {
                  required: 'Required',
                })}
                error={errors.website?.message}
              />
            </FormGroup>
          </div>

          {/* <div className={styles.root}>
            <div style={{flex: 1}}>
              <Typography
                style={{fontSize: 20, marginBottom: 5}}
                className={styles.name}
                component="p"
                variant="h3"
              >
                {currentUser?.company?.name}
              </Typography>

              <div className={styles.label}>
                <div>
                  <Typography variant="label">Email: </Typography>
                  <Typography className={styles.phone} variant="label">
                    <b>{currentUser?.company?.email}</b>
                  </Typography>
                </div>
                <div>
                  <Typography variant="label">Phone: </Typography>
                  <Typography className={styles.phone} variant="label">
                    <b>
                      {currentUser?.company?.phone
                        ? '(' +
                          currentUser?.company?.phone?.substring(0, 3) +
                          ') ' +
                          currentUser?.company?.phone?.substring(3, 6) +
                          '-' +
                          currentUser?.company?.phone?.substring(6, 10)
                        : ''}
                    </b>
                  </Typography>
                </div>
                <div>
                  <Typography variant="label">Location: </Typography>
                  <Typography className={styles.phone} variant="label">
                    <b>{currentUser?.company?.address}</b>
                  </Typography>
                </div>
              </div>
            </div>
            <Link to={`/settings/company`} className={styles.edit}>
              Edit
            </Link>
          </div> */}
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 10,
          marginTop: 20,
        }}
      >
        <Button color="danger" onClick={logout}>
          Logout
        </Button>
        {currentUser?.role === 'management' && (
          <Button color="blue" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default UserModal;
