import React from 'react';

import {MenuItemName} from 'types';

import IconBarcode from './IconBarcode';
import IconChat from './IconChat';
import IconCube from './IconCube';
import IconGear from './IconGear';
import IconHome from './IconHome';
import IconPackage from './IconPackage';
import Requests from './Requests';
import IconTruck from './IconTruck';
import IconUsers from './IconUsers';
import IconCart from './IconCart';
import IconTeams from './IconTeams';
import IconTransaction from './IconTransaction';
import IconEmployes from './IconEmployes';

interface Props {
  className?: string;
  name: MenuItemName;
  color?: 'primary' | 'secondary';
}

const components: Record<MenuItemName, React.ReactElement> = {
  barcode: <IconBarcode />,
  transactions: <IconTransaction />,
  chat: <IconChat />,
  cube: <IconCube />,
  gear: <IconGear />,
  home: <IconHome />,
  package: <IconPackage />,
  rx: <IconCart />,
  requests: <Requests />,
  truck: <IconTruck />,
  customers: <IconUsers />,
  employees: <IconEmployes />,
  teams: <IconTeams />,
  members: <IconTeams />,
  users: <IconUsers />,
  gateway: <IconGear />,
};

function MenuIcon({className, name}: Props) {
  const Icon = components[name];
  return <div className={className}>{Icon}</div>;
}

export default MenuIcon;
