import {useQuery} from 'react-query';
import {ProductService} from 'services/product.service';
import {PaginatedResponse, Product} from 'types';

export type ProductTypeFilter = '' | 'service' | 'product' | 'featured';

export type ProductFilter = {
  is_prescription?: Number;
  search?: string;
  page?: number;
  offering_type?: ProductTypeFilter;
  ordering?: string;
};

const useProductList = (filter: ProductFilter = {}) => {
  const {data, refetch, isLoading} = useQuery<PaginatedResponse<Product>>({
    queryFn: async () => {
      const productService = new ProductService();
      const response = await productService.getProducts(filter);
      return response.data;
    },
    queryKey: [
      'products',
      filter.is_prescription,
      filter.page,
      filter.search,
      filter.offering_type,
      filter.ordering,
    ],
  });

  return {
    products: data?.results || [],
    refetch,
    totalPages:
      data?.count && data.limit ? Math.ceil(data?.count / data.limit) : 0,
    isLoading,
    count: data?.count,
  };
};

export default useProductList;
