import {useRouteMatch, Switch, Route, Redirect} from 'react-router-dom';

import Customers from './Customers';
import Employees from './Employees';
import Teams from './Teams';

import {useEffect} from 'react';
import useUi from 'hooks/useUi';

function Settings() {
  const {path} = useRouteMatch();

  const {setSidebarExtended, setFullsizeContent} = useUi();

  useEffect(
    function () {
      setSidebarExtended(false);
      setFullsizeContent(true);

      return function () {
        setSidebarExtended(true);
        setFullsizeContent(false);
      };
    },
    [setSidebarExtended, setFullsizeContent]
  );

  return (
    <div>
      <Switch>
        <Route path={`${path}/customers`} exact component={Customers} />
        <Route path={`${path}/employees`} exact component={Employees} />
        <Route path={`${path}/teams`} exact component={Teams} />
        <Redirect to={`${path}/customers`} />
      </Switch>
    </div>
  );
}

export default Settings;
