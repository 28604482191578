import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import {add, format, formatISO, parseISO} from 'date-fns';
import toast from 'react-hot-toast';

// import useUi from 'hooks/useUi';
import {
  ListResponse,
  Order,
  Vehicle,
  RouteStatus,
  SelectOption,
  Driver,
} from 'types';
import api from 'api';
import {wait} from 'utils/dummy';

import useStore from './store';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import useOrderList from 'hooks/useOrderList';
import {filterOrder, orderSortFunctionMap} from 'utils/order-helpers';
import {get, reject} from 'lodash';
import {FilterOption} from './components/Filter/Filter';

export interface Route {
  id: number | string;
  driver?: Driver;
  vehicle?: Vehicle;
  start_time: string | undefined;
  start_location: string;
  end_location: string;
  orders: Order[];
  deliveries: Order[];
  canceled_deliveries: Order[];
  status: RouteStatus;
  total_time?: number;
  isClear?: boolean;
  start_latitude: number;
  start_longitude: number;
  end_latitude: number;
  end_longitude: number;
}
export interface DriverRoute {
  id: number;
  note: string;
}

const METERS_IN_MILE = 1609.34;

function roundToNearest30Minutes() {
  const roundedDate = new Date();
  const minutes = roundedDate.getMinutes();

  // Calculate the difference to the next 15-minute interval
  const remainder = minutes % 30;
  const difference = 30 - remainder;

  // Add the difference to the current time
  roundedDate.setMinutes(minutes + difference);

  return roundedDate;
}

const roundedTime = roundToNearest30Minutes();

let selectedTime = roundedTime.toISOString();

const defaultRoute: Route = {
  id: '0',
  start_time:
    format(Date.now(), 'yyyy-MM-dd') + selectedTime.slice(10, 19) + 'Z',
  start_location: '',
  end_location: '',
  orders: [],
  deliveries: [],
  canceled_deliveries: [],
  status: 'new',
  start_latitude: 40.7596958,
  start_longitude: 73.9650045,
  end_latitude: 40.7596958,
  end_longitude: 73.9650045,
};

function getRequestData(route: Route) {
  return {
    ...route,
    driver: route.vehicle?.driver?.id,
    vehicle: route.vehicle?.id,
    orders: [],
    deliveries: route.deliveries.map((o) => o.id),
    // deliveries: route.orders.map((o) => o.id),
  };
}
function getRouteCreateData(route: Route) {
  return {
    ...route,
    orders: [],
    driver: route.vehicle?.driver?.id,
    vehicle: route.vehicle?.id,
    deliveries: route.deliveries.map((o) => o.id),
  };
}

async function getVehicles() {
  const {data} = await api.get<ListResponse<Vehicle>>('/teams/');
  return data;
}

async function getRouteById({queryKey}: QueryFunctionContext) {
  const [, routeId] = queryKey;
  if (routeId) {
    const {data} = await api.get<Route>(`/routes/${routeId}/`);
    return data;
  }

  return defaultRoute;
}

function useDeliveryPlanner() {
  const {pathname} = useLocation();
  const [sortBy, setSortBy] = useState<SelectOption | null>(null);
  const {push, goBack} = useHistory();
  const {id} = useParams<{id: string}>();

  const routeId = useMemo(() => id, [id]);
  const [currentPage, setCurrentPage] = useState(1);
  const [startTime, setStartTime] = useState(new Date());
  const [filter, setFilter] = useState<FilterOption[]>([]);
  const [date, setDate] = useState<undefined>(undefined);
  const [preferenceTime, setPreferenceTime] = useState<
    undefined | {value: string; label: string}
  >(undefined);

  // const {setSidebarExtended, setFullsizeContent} = useUi();
  const distance = useStore(useCallback((state) => state.distance, []));

  const queryClient = useQueryClient();
  const {data: vehicles} = useQuery('vehicles', getVehicles);

  const {
    orders: data,
    isLoading,
    hasNextPage,
    isFetching,
    fetchNextPage,
  } = useOrderList({
    unAssignedOnly: true,
    preference_date: date,
    preference_time: preferenceTime
      ? preferenceTime.value.toLowerCase()
      : undefined,
  });

  // console.log('hasNextPage', hasNextPage);

  const [orders, setOrders] = useState(data);

  useEffect(() => {
    setOrders(data);
  }, [data]);

  let {data: route, refetch} = useQuery(['route', routeId], getRouteById, {
    initialData: defaultRoute,
    refetchOnWindowFocus: Boolean(routeId),
    refetchInterval: 0,
  });

  const handleChangeFilter = useCallback((value: FilterOption, meta: any) => {
    if (value) {
      setFilter((prev) =>
        prev.some((item) => item.label === value.label)
          ? reject(prev, value)
          : [...prev, value]
      );
    } else {
      setFilter([]);
    }
  }, []);

  const onChangeData = useCallback((value: any) => {
    setDate(value);
  }, []);

  const updateRoute = useMutation(
    'update_route',
    async function (nextRoute: Route) {
      const nextRouteData = getRequestData({
        ...route,
        ...nextRoute,
      });

      // save changes if route is already created
      if (route?.id && !nextRoute?.isClear) {
        const {data} = await api.patch<Route>(
          `/routes/${route?.id}/`,
          nextRouteData
        );
        return data;
      }

      // just return changes if route is not created
      return {
        ...route,
        ...nextRoute,
      };
    },

    {
      onMutate: async (nextRoute) => {
        await queryClient.cancelQueries(['route', routeId]);

        const previousRoute = queryClient.getQueryData<Route>([
          'routes',
          routeId,
        ]);

        queryClient.setQueryData(['route', routeId], {
          ...route,
          ...nextRoute,
        });

        return {previousRoute};
      },
      onSettled: (data) => {
        if (!data) {
          queryClient.invalidateQueries(['route', routeId]);
        }
      },
    }
  );

  const [selectedUnassignedOrders, setSelectedUnassignedOrders] = useState<
    number[]
  >([]);
  const [selectedAssignedOrders, setSelectedAssignedOrders] = useState<
    number[]
  >([]);

  const unassignedOrders = useMemo(
    function () {
      if (orders) {
        const routeOrders = route?.deliveries.map((o) => o.id) || [];

        const unsorted = [...orders]
          .filter((o) => !routeOrders.includes(o.id))
          // .filter((el) => !el.processed)
          .filter(filterOrder(filter));

        if (sortBy) {
          return unsorted.sort(orderSortFunctionMap[sortBy.value]);
        }

        return unsorted;
      }

      return [];
    },
    [filter, orders, route?.deliveries, sortBy]
  );

  useEffect(() => {
    if (route) {
      route.deliveries = route.deliveries.map(
        (item) => orders.find((el) => el.id === item.id) || item
      );
    }
  }, [orders, route]);

  const handleSendRoute = useCallback(
    async (updatedRoute: Route) => {
      push(pathname);
      await api.post(`/routes/${updatedRoute.id}/send/`);

      updateRoute.mutate({
        ...updatedRoute,
        id: '0',
        status: 'new',
        deliveries: [],
        start_latitude: 40.7596958,
        start_longitude: 73.9650045,
        end_latitude: 40.7596958,
        end_longitude: 73.9650045,
        start_location: '',
        end_location: '',
        isClear: true,
        start_time: '',
      });

      setOrders(unassignedOrders);
      setSelectedAssignedOrders([]);
      queryClient.invalidateQueries(['orders']);
    },
    [pathname, push, queryClient, unassignedOrders, updateRoute]
  );

  const handleDeleteOrder = useCallback(
    async (id: number) => {
      if (!route) return;
      await api.post(`/routes/deliveries/${id}/cancel/`);
      if (route.deliveries.length === 1) {
        goBack();
        return;
      }

      updateRoute.mutate({
        ...route,
        isClear: true,
        deliveries: route.deliveries.filter((item) => item.id !== id),
      });
    },
    [route, updateRoute, goBack]
  );

  const handleDeleteAll = useCallback(async () => {
    if (!route) return;
    await api.post(`/routes/deliveries/${route.id}/cancel-all/`);
    goBack();
  }, [route, goBack]);

  // useEffect(
  //   function () {
  //     setSidebarExtended(false);
  //     setFullsizeContent(true);

  //     return function () {
  //       setSidebarExtended(true);
  //       setFullsizeContent(false);
  //     };
  //   },
  //   [setSidebarExtended, setFullsizeContent, route]
  // );

  function onChangeUnassignedCheckbox(id: number, checked: Boolean) {
    if (checked) {
      setSelectedUnassignedOrders([...selectedUnassignedOrders, id]);
    } else {
      setSelectedUnassignedOrders(
        selectedUnassignedOrders.filter((selected) => selected !== id)
      );
    }
  }

  function onChangeUnassignedCheckboxAll(ids: number[], checked: Boolean) {
    if (checked) {
      setSelectedUnassignedOrders(ids);
    } else {
      setSelectedUnassignedOrders([]);
    }
  }

  function onChangeAssignedCheckbox(id: number, checked: Boolean) {
    if (checked) {
      setSelectedAssignedOrders([...selectedAssignedOrders, id]);
    } else {
      setSelectedAssignedOrders(
        selectedAssignedOrders.filter((selected) => selected !== id)
      );
    }
  }

  function onChangeAssignedCheckboxAll(ids: number[], checked: Boolean) {
    if (checked) {
      setSelectedAssignedOrders(ids);
    } else {
      setSelectedAssignedOrders([]);
    }
  }

  function onChangeRouteParams(
    start_location: {
      name: string;
      lat: number;
      lng: number;
    },
    end_location: {
      name: string;
      lat: number;
      lng: number;
    },
    currentVehicle?: Vehicle
  ) {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      start_location: start_location.name,
      start_time: startTime ? formatISO(startTime) : undefined,
      end_location: end_location.name,
      vehicle: currentVehicle,
    });
  }

  function onChangeStartTime(value: Date) {
    if (!route) return;
    setStartTime(value);

    updateRoute.mutate({
      ...route,
      start_time: value ? formatISO(value) : undefined,
    });
  }

  function assignSelected() {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      deliveries: [
        ...route.deliveries,
        ...unassignedOrders.filter((o) =>
          selectedUnassignedOrders.includes(o.id)
        ),
      ],
    });

    setSelectedUnassignedOrders([]);
  }

  function unassignSelected() {
    if (!route) return;

    updateRoute.mutate({
      ...route,
      deliveries: route?.deliveries.filter(
        (o) => !selectedAssignedOrders.includes(o.id)
      ),
    });

    setSelectedAssignedOrders([]);
  }

  // const calculate = useCallback(async () => {
  //   let id = route?.id;
  //   // if route is not created, create it
  //   if (route && !route.id) {
  //     const {data} = await api.post<Route>('/routes/', getRequestData(route));
  //     id = data.id;
  //   }
  //
  //   // initiate task to calculate the route
  //   await api.post(`/routes/${id}/calculate/`);
  //   let pendingRoute = route;
  //
  //   do {
  //     await wait(5000);
  //     const {data} = await api.get<Route>(`/routes/${id}/`);
  //     pendingRoute = data;
  //     alertUser(data.status);
  //   } while (pendingRoute.status === 'processing')
  //   push({search: `?id=${id}`});
  // }, [startTime, route])

  async function sendDriverNote(driverNote: DriverRoute) {
    return await api.post(`/routes/deliveries/staff_note/${driverNote.id}/`, {
      staff_note: driverNote.note,
    });
  }

  async function calculate() {
    let id = route?.id;

    if (!route?.start_location) {
      return toast.error(
        'Address or vehicle was not assigned, Please assign address and vehicle!'
      );
    }
    // if route is not created, create it
    if (route && !route.id) {
      const {data} = await api.post<Route>(
        '/routes/',
        getRouteCreateData(route)
      );
      id = data.id;
    }
    // @ts-ignore
    const address_ids = route?.deliveries.map((order) =>
      String(get(order, 'delivery_address.id', ''))
    );
    const order_ids = route?.deliveries.map((order) =>
      String(get(order, 'id', ''))
    );
    // initiate task to calculate the route
    await api.post(`/routes/${id}/calculate/`, {
      address_ids: route?.status !== 'new' ? address_ids : [],
      order_ids: route?.status !== 'new' ? order_ids : [],
    });
    let pendingRoute = route;
    do {
      await wait(5000);
      const {data} = await api.get<Route>(`/routes/${id}/`);
      pendingRoute = data;
      alertUser(data.status);
      if (data.status === 'processed') {
        updateRoute.mutate(data);
      }
    } while (pendingRoute.status === 'processing');
    push({search: `?id=${id}`});
  }

  function alertUser(status: string) {
    if (status === 'cancelled') {
      toast.error('Wrong address, Please check attached addresses!');
    }
  }
  function statsCalculator(route: undefined | Route) {
    return {
      stops: route?.deliveries.length,
      distance: `${Math.round((distance * 10) / METERS_IN_MILE) / 10} mi`,
      total_time: route?.total_time
        ? `${Math.floor(route.total_time / 60)}h ${route.total_time % 60}m`
        : '',
      end_time:
        route?.start_time && route.total_time
          ? format(
              add(parseISO(route.start_time), {minutes: route.total_time}),
              'h:mm aaa'
            )
          : '-:-- --',
    };
  }

  return {
    route: route ? route : defaultRoute,
    currentVehicle: vehicles?.results.find(
      (v) => v.id === get(route, 'team.id', 0)
    ),
    vehicles,
    unassignedOrders,
    isLoading,
    selectedUnassignedOrders,
    selectedAssignedOrders,

    stats: statsCalculator(route),

    isSubmittable: Boolean(
      route && route.start_location && route.end_location && route.start_time
    ),

    onChangeUnassignedCheckbox,
    onChangeUnassignedCheckboxAll,
    onChangeAssignedCheckbox,
    onChangeAssignedCheckboxAll,
    onChangeRouteParams,
    onChangeStartTime,
    onSendRoute: handleSendRoute,

    sortBy,
    setSortBy,
    refetch,
    filter,
    onFilterChange: handleChangeFilter,
    onChangeData,
    assignSelected,
    unassignSelected,
    sendDriverNote,
    calculate,
    date,
    setPreferenceTime,
    preferenceTime,
    setSelectedUnassignedOrders,
    setSelectedAssignedOrders,
    currentPage,
    setCurrentPage,
    hasNextPage,
    fetchNextPage,
    isFetching,
    roundedTime,
    handleDeleteOrder,
    handleDeleteAll,
  };
}

export default useDeliveryPlanner;
