import {useEffect, useRef, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';
import api from 'api';
import {Driver, ListResponse, Vehicle} from 'types';
import ViewDetails from 'components/ViewDetails';
import useSort from 'hooks/useSort';
import useDebounce from 'hooks/useDebounce';
import {EmployeStatus} from '../Employees/useEmployees';
import arrow_bottom from 'pages/DeliveryPlanner/components/Ticket/arrow_bottom.svg';

async function getVehicles({queryKey}: QueryFunctionContext) {
  const [, order, keyWord] = queryKey;
  const {data} = await api.get<ListResponse<Vehicle>>('/teams/', {
    params: {order, search: keyWord ? keyWord : undefined},
  });

  return data;
}

function mapVehicles(vehiles: Vehicle[]) {
  return vehiles.map((v) => ({
    ...v,
    members: v.drivers
      ? v.drivers
          .map((item) => item.first_name + ' ' + item.last_name)
          .slice(0, 2)
          .join(', ') + (v.drivers.length > 2 ? '...' : '')
      : '',
    view: v.id ? <ViewDetails to={`/people/teams/${v.id}`} /> : null,
  }));
}

function useVehicles() {
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_vehicle`) || ''
  );
  const [selectedRows, setSelectedRows] = useState<number | string>(0);
  const {sortBy, sortOrder, order, onSort} = useSort('name');

  const searchDebounced = useDebounce(keyWord, 400);

  const {data: vehicles, isLoading} = useQuery(
    ['vehicles', order, searchDebounced],
    getVehicles
  );

  const initializedCount = useRef(0);

  const [data, setData] = useState<Vehicle[]>([]);

  const returnDrivers = (drivers: Driver[]) => {
    return drivers.map((d) => ({
      ...d,
      view: <ViewDetails to={`/people/employees/${d.user_id}`} />,
      active: <EmployeStatus id={d.user_id} active={d.is_active === 'True'} />,
      phone: d.phone
        ? '(' +
          d.phone.substring(0, 3) +
          ') ' +
          d.phone.substring(3, 6) +
          '-' +
          d.phone.substring(6, 10)
        : '-',
    }));
  };

  type Team = {
    id: string;
    drivers: number[];
    name: string;
    model: string;
    travel_method: string;
  };

  const updateTeams = async (team1: Team, team2: Team) => {
    try {
      if (team1.id !== 'default') {
        api.put(`/teams/${team1.id}/`, team1);
      }
      if (team2.id !== 'default') {
        api.put(`/teams/${team2.id}/`, team2);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (vehicles) {
      if (initializedCount.current < 2) {
        setData(
          mapVehicles(vehicles.results).map((v) => ({
            ...v,
            id: v.id || 'default',
            drivers: returnDrivers(v.drivers || []),
            dropdown: <ArrowBottom />,
          }))
        );
      }
    }
    initializedCount.current++;
  }, [vehicles]);

  return {
    data,
    vehicles: data,
    isLoading,
    sortBy,
    sortOrder,
    onSort,
    keyWord,
    setData,
    setKeyWord,
    selectedRows,
    setSelectedRows,
    updateTeams,
    showSubMembers: selectedRows,
  };
}

const ArrowBottom = () => {
  return (
    <img
      alt="arrow bottom"
      src={arrow_bottom}
      style={{
        width: 30,
        height: 30,
        marginTop: 5,
      }}
    />
  );
};

export default useVehicles;
