import Modal from 'components/Modal';
import Typography from 'components/Typography';
import useAuth from 'hooks/useAuth';
import styles from './UserModal/UserModal.module.scss';
import api from 'api';

interface Props {
  isOpen: boolean;
  onClose: (start: boolean) => void;
}

function Guide({isOpen, onClose}: Props) {
  const {currentUser} = useAuth();

  const skipHandler = () => {
    onClose(false);
    api
      .post('/users/onboard/', {
        is_onboarded: true,
      })
      .then(() => {})
      .catch((err) => {});
  };

  return (
    <Modal
      className={styles.modal}
      showCloseButton={false}
      isOpen={isOpen}
      onClose={() => onClose(false)}
    >
      <Typography style={{textAlign: 'center', fontSize: 45}} variant="h1">
        👋 Welcome! {currentUser?.first_name}
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 5,
          marginTop: 20,
          marginBottom: 60,
        }}
      >
        <Typography
          style={{
            textAlign: 'center',
            color: '#000',
            fontWeight: '500',
          }}
          variant="h5"
        >
          We're excited to have you here. Here's how you can get started:
        </Typography>
        <Typography
          onClick={skipHandler}
          style={{
            textAlign: 'center',
            fontWeight: '500',
            color: '#2a4dff',
            cursor: 'pointer',
          }}
          variant="h5"
        >
          Skip guide
        </Typography>
      </div>
      <Typography
        className="start-guide"
        onClick={() => onClose(true)}
        style={{
          textAlign: 'center',
          fontSize: 20,
          color: '#2a4dff',
          cursor: 'pointer',
        }}
        variant="p"
      >
        Start guide
      </Typography>
    </Modal>
  );
}

export default Guide;
