import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useQuery, QueryFunctionContext} from 'react-query';

import {ListResponse, Upload, User, Vehicle} from 'types';

import api from 'api';
import roles from 'constants/roles';
import states from 'constants/states';
import toast from 'react-hot-toast';
import {get} from 'lodash';

interface Option {
  label: string;
  value: string | number;
}

interface FormValue {
  first_name: string;
  last_name: string;
  field_memebers: any[];
  travel_method: string;
  email: string;
  phone: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: Option;
  is_active: boolean;
  username: string;
  password: string;
  password_confirmation: string;

  role: Option;
  team?: Option;

  license_front: Upload;
  license_back: Upload;
  document_number?: string;
  document_state?: Option;
  issue_state?: Option;
}

async function getVehicles() {
  const {data} = await api.get<ListResponse<Vehicle>>('/teams/');

  return data;
}

async function getEmployeeById({queryKey}: QueryFunctionContext) {
  const [, employeeId] = queryKey;

  if (!employeeId) return undefined;

  const {data} = await api.get<User>(`/users/${employeeId}`);

  return data;
}

function useEmployeeForm(
  isModal: boolean,
  setUser?: (data: any) => void,
  teamId?: number
) {
  const {employeeId} = useParams<{employeeId: string}>();
  const {goBack} = useHistory();
  const [submitting, setSubmitting] = useState(false);

  console.log('teamId', teamId);

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
    watch,
    setValue,
    setError,
  } = useForm<FormValue>({
    defaultValues: {
      is_active: true,
    },
  });

  const role = watch('role');
  const license_front = watch('license_front');
  const license_back = watch('license_back');

  const license_files = useMemo(
    function () {
      return [license_front, license_back].filter((item) => Boolean(item));
    },
    [license_front, license_back]
  );

  const {data: vehicles, refetch} = useQuery('teams', getVehicles);
  const {data: employee, isLoading} = useQuery(
    ['employee', employeeId],
    getEmployeeById
  );

  const options = useMemo(
    function () {
      if (!vehicles) return [];

      return (
        vehicles.results
          // .filter((vehicle) => !vehicle.is_assigned)
          .map((v) => ({value: v.id, label: v.name}))
      );
    },
    [vehicles]
  );

  useEffect(
    function () {
      if (employee) {
        const {driver, ...user} = employee;
        if (!user) {
          return;
        }
        reset({
          ...user,
          phone: user.phone
            ? '(' +
              user.phone.substring(0, 3) +
              ') ' +
              user.phone.substring(3, 6) +
              '-' +
              user.phone.substring(6, 10)
            : '',
          state: states.find((s) => s.value === user.state),
          role: roles.find((r) => r.value === employee.role),
          team: employee?.driver?.team
            ? {
                value: employee.driver?.team.id,
                label: employee.driver?.team?.name,
              }
            : undefined,
          document_number: driver?.document_number,
          document_state: {value: driver?.document_state},
          issue_state: states.find((s) => s.value === driver?.issue_state),
          license_front: driver?.license_front,
          license_back: driver?.license_back,
          is_active: employee.is_active,
        });
      }
    },
    [employee, reset]
  );

  const handleFileUpload = useCallback(
    function (uploads: Upload[]) {
      setValue('license_front', uploads[0]);
      setValue('license_back', uploads[1]);
    },
    [setValue]
  );

  async function submit(data: FormValue) {
    if (data.phone && data.phone.length !== 14) {
      setError('phone', {
        message: 'Enter a valid phone number',
      });
      return;
    }

    setSubmitting(true);

    const user = {
      ...data,
      phone: data.phone.replace(/\D/g, ''),
      state: data.state?.value,
      role: data.role.value,
      team: data.team?.value || teamId,
    };

    try {
      const newUser = await (employeeId
        ? api.patch<User>(`/users/${employeeId}/`, user)
        : api.post<User>('/users/', user));
      if (setUser) setUser(newUser.data);
      if (!isModal) {
        goBack();
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);

      const error_message =
        get(error, 'response.data.error') ||
        get(error, 'response.data.error_message') ||
        'Something went wrong';

      toast.error(error_message);
    }

    // if (user.role === 'driver') {
    //   const driver = {
    //     id: employee?.driver?.id,
    //     document_number: data.document_number,
    //     document_state: data.document_state?.value,
    //     issue_state: data.issue_state?.value,
    //     team: data.team?.value,
    //     license_front: data.license_front?.id,
    //     license_back: data.license_back?.id,
    //     user: userData.id,
    //   };

    //   if (driver.id) {
    //     await api.patch(`/drivers/${driver.id}/`, driver);
    //   } else {
    //     await api.post('/drivers/', driver);
    //   }
    // }
  }

  const setTeam = (data: Vehicle) => {
    refetch();
    setValue('team', {
      value: data.id,
      label: data.name,
    });
  };

  return {
    employee,
    employeeId,
    options,
    submitting,
    errors,
    control,
    role: role?.value,
    license_files,
    register,
    setTeam,
    handleFileUpload,
    watch,
    setValue,
    loading: isLoading && employeeId,
    handleSubmit: handleSubmit(submit),
  };
}

export default useEmployeeForm;
