import {useEffect, useState} from 'react';
import Modal from './Modal';
import Button from './Button';
import {useHistory} from 'react-router-dom';

type Props = {
  getRef: ({open}: PermissionRef) => {};
};

export type PermissionRef = {
  open: (data: PermissionData) => void;
};

export type PermissionData = {
  title: string;
  action: string;
  cancelable?: boolean;
  buttonTitle: string;
};

function PermissionModal({getRef}: Props) {
  const [visible, setVisible] = useState(false);
  const {replace} = useHistory();

  const [data, setData] = useState<PermissionData>();

  useEffect(() => {
    let ref = {
      open: (data: PermissionData) => {
        setData(data);
        setVisible(true);
      },
    };
    getRef(ref);
  }, [getRef]);

  const buyPlan = () => {
    setVisible(false);
    if (data?.action) {
      replace(data?.action || '');
    }
  };

  return (
    <Modal
      onClose={() => setVisible(false)}
      showCloseButton={data?.cancelable}
      isOpen={visible}
      style={{
        content: {
          width: 550,
        },
      }}
    >
      <h2 style={{textAlign: 'center', marginTop: data?.cancelable ? 40 : 0}}>
        {data?.title}
      </h2>
      <div
        style={{
          marginTop: 60,
          display: 'flex',
          justifyContent: 'center',
          gap: 15,
        }}
      >
        {/* {data?.cancelable && (
          <Button onClick={() => setVisible(false)} color="gray">
            Cancel
          </Button>
        )} */}
        <Button onClick={buyPlan} color="green">
          {data?.buttonTitle}
        </Button>
      </div>
    </Modal>
  );
}

export default PermissionModal;
