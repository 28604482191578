import {AxiosResponse} from 'axios';
import {Customer, PaginatedResponse, UserAddress, createAddress} from 'types';
import {Insurance} from 'types/insurance';
import {ApiClient} from './api-client';
import {omit} from 'lodash';
import {Request} from 'types';

export type UpdateCustomerDto = {
  first_name?: string;
  last_name?: string;
  email?: string;
  birthday?: string | Date;
  phone?: string;
  addresses?: UserAddress[];
  requests?: Request[];
  avatar?: null | string;
};

export class CustomerService extends ApiClient {
  public async getInsurances(): Promise<
    AxiosResponse<PaginatedResponse<Insurance>>
  > {
    return this.get('/customers/insurance/');
  }

  public async update(
    customerId: number,
    userId: number,
    dto: UpdateCustomerDto
  ): Promise<AxiosResponse<Customer>> {
    const birthday = dto.birthday;
    await this.patch(`/users/${userId}/`, omit({...dto}, 'birthday'));
    await this.patch(`/customers/${customerId}/`, {birthday});

    if (dto.addresses) {
      await Promise.all(
        (dto.addresses || []).map((address) =>
          this.patch(
            `/customers/address/${address.id}/`,
            omit(address, 'id', 'type', 'is_valid')
          )
        )
      );
    }

    return await this.get(`/customers/${customerId}/`);
  }

  public async createAddress(
    newAddress: createAddress,
    customerId: number
  ): Promise<AxiosResponse<Customer>> {
    return await this.post(`/customers/address/`, newAddress);
  }

  public async sendNotification(
    requestId: number,
    request_drugs: Array<any>
  ): Promise<any> {
    return this.post(`/requests/${requestId}/notify-customer/`, {
      request_drugs: request_drugs,
    });
  }
  public async updateDeliveryAddress(
    requestId: number,
    addressId: any
  ): Promise<any> {
    return this.post(`/requests/${requestId}/change_delivery_address/`, {
      delivery_address_id: addressId,
    });
  }

  public async requestSignature(requestId: number): Promise<any> {
    return this.get(`/requests/${requestId}/request_signature/`);
  }
}
