import {DataItem, DataTableSchemaItem} from 'types';

import Header from './components/Header';
import Data from './components/Data';
import Loader from './components/Loader';
import Empty from './components/Empty';

import styles from './DataTable.module.scss';
import classNames from 'classnames';

interface Props {
  schema: DataTableSchemaItem[];
  subSchema: DataTableSchemaItem[];
  data: DataItem[];
  selectable: boolean;
  selectedRows: string | number;
  sortBy?: string;
  sortOrder: 'asc' | 'desc';
  onSort?: (sortBy: string, sortOrder: 'asc' | 'desc') => any;
  loading?: boolean;
  onClick?: (item: DataItem) => any;
  containerStyle?: string;
  wrapperStyle?: string;
  onAddMemberClick?: (item: DataItem) => any;
  showSubMembers: number | string;
}

export function getTemplateColumns(
  schema: DataTableSchemaItem[],
  selectable: boolean
) {
  const cols = [
    selectable ? 'minmax(32px, auto)' : undefined,
    ...schema.map((s) => s.colWidth || '1fr'),
  ]
    .filter((col) => Boolean(col))
    .join(' ');

  return cols;
}

function DataTable({
  schema,
  subSchema,
  data,
  selectable,
  selectedRows,
  sortBy,
  sortOrder,
  loading,
  onClick,
  onAddMemberClick,
  containerStyle,
  wrapperStyle,
  showSubMembers,
}: Props) {
  return (
    <div className={classNames(styles.root, containerStyle)}>
      <div
        className={classNames(styles.table, wrapperStyle)}
        style={{gridTemplateColumns: getTemplateColumns(schema, selectable)}}
      >
        <Header
          schema={schema}
          sortBy={sortBy}
          sortOrder={sortOrder}
          style={{gridTemplateColumns: getTemplateColumns(schema, selectable)}}
        />
        {!loading && (
          <Data
            schema={schema}
            data={data}
            subSchema={subSchema}
            style={{
              gridTemplateColumns: getTemplateColumns(schema, selectable),
            }}
            showSubMembers={showSubMembers}
            selectable={selectable}
            selectedRows={selectedRows}
            onAddMemberClick={onAddMemberClick}
            onClick={onClick}
          />
        )}
      </div>
      {loading && <Loader />}
      {!loading && data.length === 0 && <Empty />}
    </div>
  );
}

DataTable.defaultProps = {
  selectable: false,
  sortOrder: 'asc',
};

export default DataTable;
