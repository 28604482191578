import classNames from 'classnames';

import {DataTableSchemaItem} from 'types';

import styles from '../DataTable.module.scss';

interface Props {
  schema: DataTableSchemaItem[];
  sortBy?: string;
  sortOrder: 'asc' | 'desc';
  style?: any;
}

function Header({schema, sortBy, sortOrder, style = {}}: Props) {
  return (
    <div className={classNames(styles.row)} style={style}>
      {schema.map((s) => (
        <div
          className={classNames(styles.head, styles[s.dataKey], {
            [styles[`--${s.align}`]]: s.align,
          })}
          key={`header-${s.dataKey}`}
          data-key={s.dataKey}
          data-sortable={s.sortable}
        >
          {s.sortable ? (
            <div className={styles.header_cell}>
              <span>{s.header}</span>

              <div
                className={classNames(
                  styles.sort_button,
                  styles[`--${sortOrder}`],
                  {
                    [styles[`--active`]]: s.dataKey === sortBy,
                  }
                )}
              />
            </div>
          ) : (
            s.header
          )}
        </div>
      ))}
    </div>
  );
}

export default Header;
