import {parseJSON} from 'date-fns';
import styles from '../RequestDetails.module.scss';
import Card from 'components/Card/Card';
import CardContent from 'components/CardContent';
import DateTimePicker from 'components/DateTimePicker';
import {useEffect, useState} from 'react';
import Select from 'components/Select';
import {format} from 'date-fns';
import {SelectOption} from 'types';
// import Button from 'components/Button';

interface Props {
  preference_date: string | Date;
  preference_time: string;
  priority: string;
  priorities: SelectOption[];
  onChangePriority: (priority: any) => void;
  onChangeTime: ({
    preference_date,
    preference_time,
  }: {
    preference_date: string | null;
    preference_time: string | null;
  }) => void;
}

const options = [
  {value: 'morning', label: 'Morning'},
  {value: 'afternoon', label: 'Afternoon'},
  {value: 'evening', label: 'Evening'},
];

function PreferenceTime({
  preference_date,
  preference_time,
  onChangeTime,
  onChangePriority,
  priority,
  priorities,
}: Props) {
  const [date, setDate] = useState<Date | null>(
    preference_date ? new Date(preference_date) : null
  );
  const [time, setTime] = useState<string>(preference_time);

  useEffect(() => {
    if (typeof preference_date === 'string') {
      const [year, month, day] = preference_date.split('-');
      const date = new Date();
      date.setFullYear(parseInt(year));
      date.setMonth(parseInt(month) - 1);
      date.setDate(parseInt(day));
      setDate(date);
    }
  }, [preference_date]);

  const onChangeData = (date: any) => {
    setDate(date);

    onChangeTime({
      preference_date: date ? format(date, 'yyyy-MM-dd') : null,
      preference_time: time,
    });
  };

  const onChangeTimer = (nextVal: any) => {
    setTime(String(nextVal?.value));
    onChangeTime({
      preference_date: date ? format(date, 'yyyy-MM-dd') : null,
      preference_time: nextVal.value,
    });
  };

  return (
    <Card>
      <CardContent px="sm" pt="md" pb="sm">
        <div className={styles.label}>Priority:</div>
        <Select
          placeholder="Select priority"
          onChange={(val) => {
            onChangePriority(val?.value);
          }}
          defaultValue={
            priority
              ? {
                  value: priority,
                  label: priority[0].toUpperCase() + priority.slice(1),
                }
              : undefined
          }
          className={styles.priority}
          options={priorities}
        />
        <div className={styles.times}>
          <div>
            <div className={styles.label}>Preference date:</div>
            <div className={styles.calendarRoot}>
              <DateTimePicker
                showTimeSelect={false}
                dateFormat="MM/dd/yy E"
                value={date ? parseJSON(date) : undefined}
                onChange={onChangeData}
              />
            </div>
          </div>
          <div>
            <div className={styles.label}>Preference time:</div>
            <Select
              className={styles.select}
              placeholder="Select time"
              onChange={onChangeTimer}
              value={time ? {value: time, label: time.toLowerCase()} : null}
              options={options}
              size="md"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default PreferenceTime;
