import Button from 'components/Button';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';
import moment from 'moment';
import {useHistory, useLocation} from 'react-router-dom';
import useBilling from './useBilling';
import NewCard from './components/NewCard';
import {useState} from 'react';
import Typography from 'components/Typography';

const BillingDetails = () => {
  const {data, refetch} = useBilling();
  const {currentUser} = useAuth();
  const {push, goBack} = useHistory();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(
    get(location, 'state.defaultOpen', false)
  );

  // useEffect(() => {
  //   if (!isLoading) {
  //     setIsOpen(!get(data, '[0].card.last4', ''));
  //   }
  // }, [isLoading, data]);

  return (
    <div style={styles.container}>
      <h1>Billing details</h1>
      {!!get(currentUser, 'company.plan', '') && (
        <div style={styles.section}>
          <div style={styles.currentPlan}>
            <div>
              <h2>
                {get(currentUser, 'company.plan.plan_name', '')} plan with{' '}
                {get(currentUser, 'company.plan.subscription_quantity', 0)}{' '}
                {get(currentUser, 'company.plan.subscription_quantity', 0) > 1
                  ? 'seats'
                  : 'seat'}
              </h2>
              <p>
                Bills monthly at $
                {get(
                  currentUser,
                  'company.plan.subscription_total_billing_amount',
                  ''
                )}{' '}
                USD per month
              </p>
              <p>
                Renews on{' '}
                {moment(
                  get(
                    currentUser,
                    'company.plan.subscription_current_period_end',
                    new Date()
                  )
                ).format('ll')}
              </p>
              <p>
                Supports up to{' '}
                {get(currentUser, 'company.plan.subscription_quantity', 0)}{' '}
                people (
                {get(
                  currentUser,
                  'company.plan.active_subscription_quantity',
                  0
                )}{' '}
                currently active)
              </p>
              <p
                onClick={() => push('/settings/billing/history')}
                style={styles.link}
              >
                See billing history
              </p>
            </div>
            {/* @ts-ignore */}
            <div style={styles.actions}>
              <Button
                color="green"
                onClick={() => {
                  push('/settings/plans');
                }}
              >
                Update plan
              </Button>
            </div>
          </div>
        </div>
      )}

      <div style={styles.section}>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Payment method</b>
          </p>
          <p style={{flex: 1}}>
            {get(data, '[0].card.last4', '')
              ? `**** **** **** ${get(data, '[0].card.last4', '')}`
              : ''}
          </p>
          <p
            onClick={() => setIsOpen(true)}
            style={{
              ...styles.link,
              flex: 1,
              textAlign: 'right',
              margin: 'auto 0',
            }}
          >
            {get(data, '[0].card.last4', '')
              ? 'Update credit card'
              : 'Add credit card'}
          </p>
        </div>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Receipt recipients</b>
          </p>
          <p style={{flex: 1}}>{get(currentUser, 'company.email', '')}</p>

          <p
            onClick={
              () => push('/settings/company')
              // handleOpenExternalPage('portal_update_billing_information_url')
            }
            style={{
              ...styles.link,
              flex: 1,
              textAlign: 'right',
              backgroundColor: '#f9f9f9',
              margin: 'auto 0',
            }}
          >
            Update recipients
          </p>
        </div>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Web address</b>
          </p>
          <p style={{flex: 1}}>
            {get(currentUser, 'company.website', '') || '-'}
          </p>
          <p
            onClick={
              () => push('/settings/company')
              // handleOpenExternalPage('portal_update_billing_information_url')
            }
            style={{
              ...styles.link,
              flex: 1,
              textAlign: 'right',
              backgroundColor: '#f9f9f9',
              margin: 'auto 0',
            }}
          >
            Update
          </p>
        </div>
        <div style={styles.tableRow}>
          <p style={{flex: 1}}>
            <b>Account created</b>
          </p>
          <p style={{flex: 1}}>
            {moment(get(currentUser, 'customer.created_at', new Date())).format(
              'LL'
            )}
          </p>
          <p style={{flex: 1}}></p>
        </div>
      </div>

      <Typography style={{textAlign: 'right'}} variant="h2">
        Balance: <b>{'$' + get(currentUser, 'company.plan.balance', 0)}</b>
      </Typography>
      <NewCard
        id={get(data, '[0].id', '')}
        card={get(data, '[0].card.last4', '')}
        onClose={(success) => {
          if (success) {
            goBack();
          }
          setIsOpen(false);
        }}
        isOpen={isOpen}
        refetch={refetch}
      />
    </div>
  );
};

const styles = {
  container: {
    width: '80%',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f9f9f9',
  },
  section: {
    marginBottom: '20px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
  currentPlan: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    display: 'flex',
    borderBottom: '1px solid #e0e0e0',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: 0,
  },
};

export default BillingDetails;
